import React from 'react';
import { StyledTextField, StyledCompactTextField } from './styled';

const TextField = ({ compact, ...rest }) => {
  return compact ? (
    <StyledCompactTextField {...rest} />
  ) : (
    <StyledTextField {...rest} />
  );
};

export default TextField;
