import styled from 'styled-components';
import { TextField } from '@material-ui/core';

export const StyledTextField = styled(TextField)`
  && {
    min-width: 200px;
    max-width: 400px;
  }
`;

export const StyledCompactTextField = styled(TextField)`
  && {
    min-width: 150px;
    max-width: 400px;
  }
  @media (max-width: 600px) {
    && {
      min-width: 50px;
      max-width: 90px;
    }
  }
`;
